<template>
  <div id="terminal_history_component">
    <div class="terminal_history_container">
      <div class="terminal_history_container__item title">
        <label>Журнал изменений</label>
      </div>
      <div class="terminal_history_container__item select_input_with_title">
        <div class="select_input_title"><label>Тип изменения</label></div>
        <search-select
                  :options="unpackHistoryFileds"
                  v-model:selected="selectedHistoryField"
                  @change="onSelectedHistoryChange"
                  style="width: 200px"
                />
        <!-- <select
          v-model="selectedHistoryField"
          @change="onSelectedHistoryChange"
          class="select_input_selected"
        >
          <option :value="null">Не выбрано</option>
          <option v-for="(value, key, index) in historyFields" :key="index">
            {{ key }}
          </option>
        </select> -->
      </div>

      <div class="terminal_history_container__item history">
        <div
          class="history_not_found"
          v-if="getHistorySimple.length === 0 && selectedHistoryField != null"
        >
          <label>Не найдено</label>
        </div>
        <info-container
          v-if="selectedHistoryField != null"
          :data="getHistorySimple"
        />

        <!-- <info-container  v-if="['displays'].includes(this.selectedHistoryField)" titleRight="Было" titleLeft="Стало"
         :data="getHistoryMultyLine" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "info-container": defineAsyncComponent(() =>
      import("@/components/InfoContainer.vue")
    ),
    "search-select": defineAsyncComponent(() =>
      import("@/components/SearchSelect.vue")
    ),
  },
  props: {
    terminalId: {
      type: Number,
    },
  },
  data() {
    return {
      selectedHistoryField: null,
      historyFields: {
        kernel: "distributions",
        initrd: "distributions",
        template: "template",
        // cpu: "devices.cpu",
        memory: "devices.totalMemSizeMB",
        display: "displays.outputs",
        terminalState: "terminalState",
      },
      currentHistory: [],
    };
  },
  methods: {
    // updacks distribution history to current history format
    unpackDistributionToCurrentHistoryFormat(data, distKey) {
      const finalData = [];
      data.forEach((e) => {
        const value = e.value.filter((el) => el.type === distKey)[0];
        if (
          finalData.length === 0 ||
          finalData.at(-1).value !== value.distribution
        ) {
          finalData.push({ date: e.date, value: value.distribution });
        }
      });
      return finalData;
    },

    // updacks display history to current history format
    unpackDisplaysToCurrentHistoryFormat(data) {
      const finalData = [];
      data.forEach((el) => {
        const outputs = el.value
          .sort((a, b) => a.id - b.id)
          .map((e) => `${e.currentResolution} ${e.name}`);
        if (
          finalData.length === 0 ||
          finalData.at(-1).value.toLocaleString() !== outputs.toLocaleString()
        ) {
          finalData.push({ date: el.date, value: outputs });
        }
      });
      return finalData;
    },

    // catch the event of gistory change
    async onSelectedHistoryChange() {
      // process not selected choice
      if (this.selectedHistoryField === null) {
        this.currentHistory = [];
        return;
      }

      // process option choice
      const backFiled = this.historyFields[this.selectedHistoryField];
      let data = await this.getHistoryFromApi(this.terminalId, backFiled);

      // upnack distribution history
      if (["kernel", "initrd"].includes(this.selectedHistoryField)) {
        this.currentHistory = this.unpackDistributionToCurrentHistoryFormat(
          data,
          this.selectedHistoryField
        );
        return;
      }

      // upnack display history
      if ("display" === this.selectedHistoryField) {
        this.currentHistory = this.unpackDisplaysToCurrentHistoryFormat(data);
        return;
      }

      this.currentHistory = data;
    },
    async getHistoryFromApi(terminalId, filed) {
      const result = this.$http
        .post(`${this.$backEndUrl}/v1/proxy/aggregation`, {
          url: `/v2/history?terminalId=${terminalId}&field=${filed}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy aggregation obtained (history data)",
            res
          );
          if (res.data.statusCode === 200) {
            console.log(
              "Response from aggregation obtained (history data)",
              res.data.payload
            );
            return res.data.payload;
          }
        })
        .catch((error) => {
          console.log("Error to get search data", error);
        });

      return await result;
    },
  },

  computed: {
    unpackHistoryFileds() {
      return Object.keys(this.historyFields)

    },
    getHistorySimple() {
      let data = [];

      this.currentHistory.forEach((el, i) => {
        if (i === 0) {
          data.push({
            title: "default",
            titleInfo: new Date(el.date).toString(),
            value: el.value,
          });
        } else if (i + 1 < this.currentHistory.length) {
          data.push({
            title: this.currentHistory[i - 1].value,
            titleInfo: Date(this.currentHistory[i + 1].date).toString(),
            value: el.value,
          });
        } else {
          data.push({
            title: this.currentHistory[this.currentHistory.length-2].value,
            titleInfo: Date(this.currentHistory[this.currentHistory.length-1].date).toString(),
            value: el.value,
          });

        }
      });

      return data;
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/inputs.less";

#terminal_history_component {
  .terminal_history_container {
  display: flex;
  flex-direction: column;
  padding: 25px 25px;

  .terminal_history_container__item {
    &.title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    &.select_input_with_title {
      margin-top: 20px;
      width: 180px;
    }

    &.history {
      margin-top: 20px;
      overflow-y: scroll;
      max-height: 60vh;

      .history_not_found {
        margin-top: 25px;
      }

      .line_title_container {
        width: 50%;
      }

      .line_value {
        width: 50%;
      }

      .title_left {
        width: 50%;
      }

      .title_right {
        width: 50%;
      }
    }
  }
}
}
</style>
